import React from "react"
import PageHeadBanner from "../components/PageHeadBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"
const CareerCouncelling = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"Career Councelling Cell"} siteMetadata={siteData} />
      <PageHeadBanner
        title={"Career Councelling"}
        subtitle={"Career Councelling and Quality Assurance Cell"}
      />

      <section className="terms-conditions-area ptb-100">
        <div className="container">
          <div className="main-default-content">
            {/* <h3>1. College Uniform</h3> */}
            <p>
              The college has introduced a career guidance and infomation cell
              for the upliftmentof the students of the college. We will provide
              career guidance, training and support services for students who
              seek to become more successful and have different perspectives on
              employment and skill. We will highlight opportunities to gain new
              ideas and help students find the jobs they need and want,
              including jobs that have been successful and are now open to all
              over the world.
            </p>
          </div>
        </div>
      </section>
    </>
  )
})

export default CareerCouncelling
